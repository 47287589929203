import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2024/luth-brain-drain/1.jpg';
import blogd2 from '../../assets/images/2024/luth-brain-drain/2.jpg';
import blogd3 from '../../assets/images/2024/luth-brain-drain/3.jpg';
import blogd4 from '../../assets/images/2024/luth-brain-drain/4.jpg';
import blogd5 from '../../assets/images/2024/luth-brain-drain/5.jpg';
import blogd6 from '../../assets/images/2024/luth-brain-drain/6.jpg';
import blogd7 from '../../assets/images/2024/luth-brain-drain/7.jpg';
import blogd8 from '../../assets/images/2024/luth-brain-drain/8.jpg';
import blogd9 from '../../assets/images/2024/luth-brain-drain/9.jpg';
import blogd10 from '../../assets/images/2024/luth-brain-drain/10.jpg';
import blogd11 from '../../assets/images/2024/luth-brain-drain/11.jpg';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  Lagos University Teaching Hospital (LUTH) Marks 62nd
                  Anniversary with Special presentation on Brain Drain
                </h2>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The Lagos University Teaching Hospital (LUTH) commemorated its
                  62nd anniversary with a presentation focused on the theme
                  "Brain Drain: The Imperative of Repositioning the Nigerian
                  Healthcare Sector."
                  <br />
                  In his opening remarks, Professor Adeyemo expressed deep
                  appreciation for the dedication and commitment of the
                  hospital’s staff, emphasizing that LUTH's achievements are a
                  direct result of their efforts. “Whatever successes we
                  celebrate today would not have been possible without the hard
                  work and resilience of the hospital staff,” he stated.
                  <br />
                  During the events the Keynote speaker Prof. Chris Bode in his
                  presentation highlighted the growing phenomenon of brain
                  drain, locally known as the "Japa syndrome." Professor Chris
                  Bode explained the meaning behind the Yoruba term “Japa,”
                  which refers to fleeing from hardship with no intention of
                  returning. This trend is increasingly visible among Nigerian
                  healthcare professionals, many of whom are seeking better
                  opportunities abroad.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Consultative Services Ongoing at the Outreach
                </span> */}
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Professor Bode noted that migration is a global phenomenon
                  affecting all species, but in Nigeria’s case, it has serious
                  consequences for the healthcare sector. “We are losing doctors
                  and nurses after investing significant resources in training
                  them,” he said. He revealed that the country faces a critical
                  shortage, with one doctor for every 4,000 Nigerians—far below
                  the World Health Organization's recommended ratio of one
                  doctor per 600 people.
                  <br></br>
                  Citing recent data, Prof. Bode stressed that Nigeria needs at
                  least 240,000 doctors to meet healthcare demands, but current
                  production rates suggest that the country will still be short
                  in 100 years unless drastic changes are made. He lamented the
                  paradox of unemployed doctors despite nationwide shortages,
                  attributing the issue to the concentration of medical
                  professionals in urban centers like Lagos and Abuja.
                  <br></br>
                  He highlighted Poor remuneration as the leading cause of
                  migration, with 91% of healthcare workers citing inadequate
                  salaries as their reason for leaving. “How can someone spend
                  nearly 30 years in training only to earn a pittance?" Prof.
                  Bode asked, warning that if the trend continues, more staff
                  will leave, worsening Nigeria’s healthcare crisis. He also
                  pointed to poor working conditions, insecurity, and a lack of
                  career prospects as additional push factors.
                  <br></br>
                  The event also touched on cultural challenges faced by
                  Nigerian professionals abroad, including racism and
                  loneliness. “Some people thrive overseas, but others struggle
                  with isolation,” he said, stressing the importance of
                  self-awareness when considering migration.
                  <br></br>While acknowledging the benefits of migration—such as
                  remittances, advanced training, and better living
                  conditions—Professor Bode questioned the long-term impact.
                  <br></br>
                  Professor Bode concluded by urging the government to improve
                  salaries, working conditions, and rural infrastructure to
                  encourage healthcare workers to stay. “If we want different
                  results, we must do things differently,” he emphasized.
                  <br />
                  The presentation ended with a call for collective action to
                  address the brain drain crisis and reposition Nigeria’s
                  healthcare sector for sustainable growth. 
                  <br />
                  The highlight of the
                  event was the recognition and presentation of awards by the
                  Chief Medical Director, Prof. Wasiu Lanre Adeyemo, FAS, to the
                  best clinical team and the top-performing non-clinical
                  department in the hospital.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Baale of Mashewele community, community elder, HOD of
                  Community Health Dept Prof Ogunnowo, Dr Roberts ,Mushin LGA
                  Health Educator Mrs Akinterinwa and Residents in Community
                  Health.
                </span> */}
                {/* <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Other pictures as found below:
                </p> */}
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd7} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd9} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd11} height={370} />
                  </div>
                  <div className='col-lg-12'>
                    <img src={blogd10} height={370} />
                  </div>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Fig 1: Residents at the department before the program
                  <br />
                  Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach.
                </span> */}
                <div className='row'>
                  <div className='col-lg-12'>
                    {/* <img src={blogd9} height={370} /> */}
                  </div>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Residents dispensing medications in advance for the outreach
                </span> */}
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'></a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Medical Outreach to Mashewele Community  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
